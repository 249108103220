import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import MailchimpForm from "../../components/mailchimp-form"


export default function MailchimpCampaign({ data: { mailchimpCampaign } }) {
  const { html, settings, send_time } = mailchimpCampaign
  let stripped_html
  if (html) {
    stripped_html = html
    // remove mailchimp replacement codes
    stripped_html = html.replace(/\*\|[A-Z:_]+\|\*/g, "")
    stripped_html = stripped_html.replace(/<a[^<]+?href="".*?>.*?<\/a>/g, "")
    stripped_html = stripped_html.replace(/<a[^<]+?href="https?:\/\/(gayeoakes.com|www.facebook.com|twitter.com).+?".*?>.*?<\/a>/g, "")    
    stripped_html = stripped_html.replace(/<h3.+?>Recent Articles:<\/h3>/g, "")
    stripped_html = stripped_html.replace(/style=".+?"/g, "")
    stripped_html = stripped_html.replace(/width="[0-9]+?"/g, "")
    stripped_html = stripped_html.replace(/<style>.+<\/style>/ig, "")
    stripped_html = stripped_html.replace(/<meta.+?>/ig, "")
    stripped_html = stripped_html.replace(/h2/g, "h3")
    stripped_html = stripped_html.replace(/h1/g, "h2 style=\"text-align: center\"")
    stripped_html = stripped_html.replace(/<!--.*?-->/ig, "")
    stripped_html = stripped_html.replace(/<!--\[if.*?endif\]-->/ig, "")
    stripped_html = stripped_html.replace(/This email was sent to/ig, "")

  } else {
    stripped_html = ""
  }
  return (
    <Layout>
      <div className="newsletter p-4 md:p-8 bg-white border drop-shadow-2xl">
        <header>
          <h1>Gaye’s Studio News</h1>
          <div className="md:px-11 mt-4 mb-2 py-4 border-t border-b">
            <p className="text-center"><strong>Subject:</strong> {settings.subject_line}</p>
          </div>
        </header>
        <div className="w-full" dangerouslySetInnerHTML={{ __html: stripped_html }} />
        <article>
          <h2>Subscribe to my studio news here</h2>
          <MailchimpForm />
        </article>
      </div>
    </Layout>
  );
}


export const pageQuery = graphql`
  query ($id: String!) {
    mailchimpCampaign(id: { eq: $id }) {
      fields {
        slug
        title
      }
      status
      settings {
        subject_line
        title
      }
      html
    }
  }
`
